import { Link, useNavigate } from '@remix-run/react';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { $path } from 'remix-routes';

import {
  type DtoFeaturedItem,
  type DtoGamePack,
  type DtoPage,
  type DtoPageSection,
  type DtoProduct,
  type DtoProgram,
  type DtoTag,
  EnumsPageName,
  EnumsPageRowLayout,
} from '@lp-lib/api-service-client/public';

import { type AppAnalytics } from '../../../src/analytics/app/shared';
import { DoubleRightArrow } from '../../../src/components/icons/Arrows';
import { type Tag } from '../../../src/types';
import { type GamePack } from '../../../src/types/game';
import { fromDTOGamePack, fromDTOGamePacks } from '../../../src/utils/api-dto';
import { type PageSectionBodyProgramsProps } from '../DynamicPage/PageSectionBody';
import { PageSectionList } from '../DynamicPage/PageSectionList';
import {
  type PageSectionRowFeaturedCarouselContainerProps,
  type PageSectionRowFeaturedTagContainerProps,
  type PageSectionRowTagCollectionContainerProps,
} from '../DynamicPage/PageSectionRow';
import {
  PageDisplayName,
  pageSectionAnchor,
  selectSectionByAnchor,
} from '../DynamicPage/utils';
import { FeaturedCard, FeaturedSlice } from '../Featured/FeaturedCard';
import { FeaturedList } from '../Featured/FeaturedList';
import { type GameLikeFilterOptions } from '../GamePack/GamePackFilters';
import { makeAnonPackUrl } from '../GamePack/utils';
import { TagCard } from '../Tag/TagCard';
import { AnonGamePackCard } from './AnonGamePack';
import { AnonPublicLibraryLayout } from './AnonPublicLibraryLayout';
import { AnonPublicLibraryNav } from './AnonPublicLibraryNav';
import { PublicLibraryBackground } from './PublicLibraryBackground';
import {
  encodeFilterOptions,
  PublicLibraryFilter,
  PublicLibraryFilterKeysMain,
} from './PublicLibraryFilter';
import {
  PublicLibraryGamePackCarousel,
  PublicLibraryGamePackList,
  PublicLibraryTagCarousel,
  PublicLibraryTagList,
} from './PublicLibraryLayout';
import { PublicLibraryProgramsSection } from './PublicLibraryPrograms';

function AnonPrograms(
  props: PageSectionBodyProgramsProps & AnonPublicLibraryIndexProps
) {
  const { pageName, programs, section, sectionIndex, analytics } = props;

  const handleClick = (program: DtoProgram) => {
    analytics?.trackProgramCardClicked({
      pageName: PageDisplayName(pageName),
      sectionTitle: section.title,
      sectionIndex,
      programId: program.id,
      programName: program.name,
    });
    window.open($path('/explore/programs/:id', { id: program.id }));
  };

  return (
    <PublicLibraryProgramsSection programs={programs} onClick={handleClick} />
  );
}

function GamePackFeaturedAction(props: { onClick?: () => void }) {
  return (
    <div className='flex gap-4' onClick={(e) => e.stopPropagation()}>
      <button
        type='button'
        className={
          'btn-delete rounded min-w-40 h-10 flex items-center justify-center px-4 text-sms font-medium'
        }
        onClick={props.onClick}
      >
        <p>Find Out More</p>
      </button>
    </div>
  );
}

function AnonFeaturedCarousel(
  props: PageSectionRowFeaturedCarouselContainerProps &
    AnonPublicLibraryIndexProps
) {
  const { section, rowIndex, sectionIndex, featuredItems, analytics } = props;

  const navigate = useNavigate();
  const [filterOptions, setFilterOptions] = useState<
    Partial<GameLikeFilterOptions>
  >({});

  const handleClickGamePack = (pack: GamePack, itemIndex: number) => {
    analytics?.trackLibraryGamePackClicked({
      pageName: PageDisplayName(props.pageName),
      sectionIndex,
      sectionTitle: section.title,
      rowIndex: rowIndex,
      rowType: 'featured-carousel',
      itemIndex,
      packId: pack.id,
      packName: pack.name,
    });

    window.open(makeAnonPackUrl(pack));
  };

  const handleClickTag = (tag: Tag, itemIndex: number) => {
    analytics?.trackLibraryCategoryCardClicked({
      pageName: PageDisplayName(props.pageName),
      sectionIndex,
      sectionTitle: section.title,
      rowIndex: rowIndex,
      rowType: 'featured-carousel',
      itemIndex,
      categoryId: tag.id,
      categoryName: tag.name,
    });
    window.open(
      $path('/explore/tags/:slug', {
        slug: tag.slug,
      }),
      '_blank'
    );
  };

  return (
    <div className='w-full flex flex-col items-center gap-5'>
      <div className='w-full max-w-[1380px]'>
        <FeaturedList
          hideBullets
          items={featuredItems}
          renderSlide={(props) => <FeaturedSlice {...props} />}
          renderCard={(props) => (
            <FeaturedCard
              {...props}
              onGamePackClick={(pack) =>
                handleClickGamePack(fromDTOGamePack(pack), props.index)
              }
              renderGamePackAction={(pack) => (
                <GamePackFeaturedAction
                  onClick={() =>
                    handleClickGamePack(fromDTOGamePack(pack), props.index)
                  }
                />
              )}
              onTagClick={(tag) => {
                handleClickTag(tag, props.index);
              }}
            />
          )}
          analytics={analytics}
        />
      </div>

      <PublicLibraryFilter
        showMenuKeys={PublicLibraryFilterKeysMain}
        filterOptions={filterOptions}
        withApplyButton
        onChange={setFilterOptions}
        onApply={(options) => {
          const params = encodeFilterOptions(options);
          navigate($path('/explore/search', params));
        }}
      />
    </div>
  );
}

function AnonFeaturedTag(
  props: PageSectionRowFeaturedTagContainerProps & AnonPublicLibraryIndexProps
) {
  const {
    pageName,
    tag,
    section,
    sectionIndex,
    row,
    rowIndex,
    groupedGamePacks,
    analytics,
  } = props;

  const handleClickGamePack = (pack: GamePack, itemIndex: number) => {
    analytics?.trackLibraryGamePackClicked({
      pageName: PageDisplayName(props.pageName),
      sectionIndex,
      sectionTitle: section.title,
      rowIndex,
      rowType: 'featured-tag',
      categoryId: tag.id,
      categoryName: tag.name,
      itemIndex,
      packId: pack.id,
      packName: pack.name,
    });

    window.open(makeAnonPackUrl(pack, { tag }));
  };

  const showHeader =
    pageName !== EnumsPageName.PageNameLiveOtp &&
    pageName !== EnumsPageName.PageNameLiveLoggedIn;

  return (
    <div className='w-full'>
      {showHeader && (
        <Link
          to={$path('/explore/tags/:slug', { slug: tag.slug })}
          onClick={() => analytics?.trackLibraryCategoryClicked(tag.name)}
          className='btn flex items-center gap-8 group'
        >
          <p className={`text-xl font-bold`}>{tag.name}</p>
          <div className='flex items-center gap-2 opacity-0 group-hover:opacity-100'>
            <p className='text-sms'>View All</p> <DoubleRightArrow />
          </div>
        </Link>
      )}

      {row.layout === EnumsPageRowLayout.PageRowLayoutExpanded ? (
        <PublicLibraryGamePackList
          packs={fromDTOGamePacks(groupedGamePacks[tag.id] ?? [])}
          renderCard={(pack, index) => (
            <AnonGamePackCard
              pack={pack}
              onClick={() => handleClickGamePack(pack, index)}
            />
          )}
        />
      ) : (
        <PublicLibraryGamePackCarousel
          packs={fromDTOGamePacks(groupedGamePacks[tag.id] ?? [])}
          renderCard={(pack, index) => (
            <AnonGamePackCard
              pack={pack}
              onClick={() => handleClickGamePack(pack, index)}
            />
          )}
        />
      )}
    </div>
  );
}

function AnonTagCollection(
  props: PageSectionRowTagCollectionContainerProps & AnonPublicLibraryIndexProps
) {
  const { section, sectionIndex, row, rowIndex, collection, analytics } = props;

  const handleClickTag = (tag: DtoTag, itemIndex: number) => {
    analytics?.trackLibraryCategoryCardClicked({
      pageName: PageDisplayName(props.pageName),
      sectionIndex,
      sectionTitle: section.title,
      rowIndex,
      rowType: 'tag-collection',
      itemIndex,
      categoryId: tag.id,
      categoryName: tag.name,
    });

    window.open($path('/explore/tags/:slug', { slug: tag.slug }));
  };

  return (
    <div className='w-full'>
      <div className={`text-xl font-bold`}>{collection.name}</div>

      {row.layout === EnumsPageRowLayout.PageRowLayoutExpanded ? (
        <PublicLibraryTagList
          tags={collection.tags || []}
          renderCard={(tag, index) => (
            <Link
              className='w-full'
              // SEO purpose
              onClick={(e) => e.preventDefault()}
              to={$path('/explore/tags/:slug', { slug: tag.slug })}
            >
              <TagCard tag={tag} onClick={() => handleClickTag(tag, index)} />
            </Link>
          )}
        />
      ) : (
        <PublicLibraryTagCarousel
          tags={collection.tags || []}
          renderCard={(tag, index) => (
            <Link
              className='w-full'
              // SEO purpose
              onClick={(e) => e.preventDefault()}
              to={$path('/explore/tags/:slug', { slug: tag.slug })}
            >
              <TagCard tag={tag} onClick={() => handleClickTag(tag, index)} />
            </Link>
          )}
        />
      )}
    </div>
  );
}

export interface AnonPublicLibraryIndexProps {
  pageName: EnumsPageName;
  page: DtoPage;
  product: DtoProduct | null;
  programs: DtoProgram[];
  featuredItems: DtoFeaturedItem[];
  groupedGamePacks: Record<string, DtoGamePack[]>;
  hideCategoriesHeader?: boolean;

  analytics?: AppAnalytics;
}

export function AnonPublicLibraryIndex(props: AnonPublicLibraryIndexProps) {
  const { pageName, page } = props;

  const [focusedSection, setFocusedSection] = useState<DtoPageSection | null>(
    page.sections?.[0] || null
  );

  useEffectOnce(() => {
    const targetSection = selectSectionByAnchor(
      page.sections,
      window.location.hash
    );
    if (!targetSection) return;

    setFocusedSection(targetSection);
    const element = document.getElementById(pageSectionAnchor(targetSection));
    if (element) {
      element.scrollIntoView({ behavior: 'instant', block: 'start' });
    }
  });

  return (
    <AnonPublicLibraryLayout
      secondaryNav={
        <AnonPublicLibraryNav
          pageName={pageName}
          page={page}
          isSectionSelected={(section) => section.id === focusedSection?.id}
        />
      }
    >
      <PublicLibraryBackground page={page} />

      <div className='relative w-full flex flex-col'>
        <PageSectionList
          pageName={pageName}
          sections={page.sections || []}
          onFocusedSectionChange={(section) => setFocusedSection(section)}
          renderPrograms={(p) => <AnonPrograms {...p} {...props} />}
          renderFeaturedCarousel={(p) => (
            <AnonFeaturedCarousel {...p} {...props} />
          )}
          renderFeaturedTag={(p) => <AnonFeaturedTag {...p} {...props} />}
          renderTagCollection={(p) => <AnonTagCollection {...p} {...props} />}
          analytics={props.analytics}
        />
      </div>
    </AnonPublicLibraryLayout>
  );
}
